import { debounce } from 'perfect-debounce'
import { promiseTimeout } from '@vueuse/core'
import { Howl } from 'howler'
import { ofetch } from 'ofetch'
import GlassonApis from '@/shared/apis'
import { CDN_ASSETS, DELTA_FACE_WIDTH } from '@/configs/app.config'

const DEBOUNCE_TIME = 100
const SOUND_TIME_OUT = 1500 // wait 1.5s
// const SCALE_BRIDGE = 1.1 // 2cm

const initialState = {
  firstValidFace: false,
  firstMeasuring: false,
  validFace: false,
  holdCard: false,
  measuring: false,
  getPd: false,
  finish: false,
  tick: 0,
  waiting: false,
}
export function useMeasureConfig() {
  const { loadingStore, appStore } = useStores()
  const { values: customizeData } = storeToRefs(appStore)
  let faceInfo: AnyObject | null = null
  let faceView: FaceView | null = null
  let captureValidatorView: CatureValidatorView | null = null
  const state = reactive({ ...initialState })

  const camera = templateRef<HTMLDivElement>('camera')
  const videoCanvas = templateRef<HTMLCanvasElement>('videoCanvas')

  const soundCapture = new Howl({
    src: [CDN_ASSETS.soundCapture],
  })
  const sound41 = new Howl({
    src: [CDN_ASSETS.sound41],
  })
  const sound42 = new Howl({
    src: [CDN_ASSETS.sound42],
  })
  const sound43 = new Howl({
    src: [CDN_ASSETS.sound43],
  })

  const {
    current,
    goToNext,
    goTo,
    isCurrent,
  } = useStepper(['introduction', 'measure', 'result'])

  const faceValidColor = computed(() => {
    if (state.validFace || state.finish)
      return 'success'
    if (state.firstValidFace && !state.validFace)
      return 'error'
    return 'default'
  })

  const cardValidColor = computed(() => {
    if (state.holdCard || state.finish)
      return 'success'
    if (state.firstMeasuring && !state.holdCard)
      return 'error'
    return 'default'
  })

  const holdCardColor = computed(() => {
    if (state.getPd || state.finish)
      return 'success'
    if (state.firstMeasuring && !state.holdCard)
      return 'error'
    return 'default'
  })

  const { execute } = useAsyncState(
    async (formData: FormData) => await ofetch<{ result: { pixel_meter: number, left_pd: number, right_pd: number, pupil_distance: number } }>('https://pd-measurement-x.sk-global.io/api/measure_api', {
      method: 'POST',
      body: formData,
    }),
    null,
    {
      immediate: false,
      onSuccess: async (data) => {
        // * Record API Usage even if the result is not valid
        GlassonApis.recordApiUsage(`${customizeData.value.layout.accountId}`, 'pd')

        if (!data || !faceInfo)
          return
        // consola.info('data', data.result)

        // * Update PD Measure
        const roundedPD = round(data.result.pupil_distance, 0)
        const roundedLeftPD = round(data.result.left_pd, 0)

        appStore.updatePdMeasure({
          faceWidth: round((faceInfo.faceDistance * data.result.pixel_meter) + DELTA_FACE_WIDTH, 0),
          bridgeDistance: round(faceInfo.noseDistance * data.result.pixel_meter /* * SCALE_BRIDGE */, 0),
          innerCanthalDistance: round(faceInfo.eyeDistance * data.result.pixel_meter, 0),
          pupilDistance: roundedPD,
          leftPd: roundedLeftPD,
          rightPd: roundedPD - roundedLeftPD,
        })

        Object.assign(state, {
          validFace: false,
          holdCard: false,
          getPd: false,
          measuring: false,
          firstMeasuring: false,
          finish: true,
        })

        playSoundBy('sound43')
      },
    },
  )

  const loadMeasurement = debounce(async (canvasId = 'video_canvas') => {
    try {
      loadingStore.startLoading()

      // * Step 1: Set canvas size
      videoCanvas.value.width = camera.value?.clientWidth ?? '100%'
      videoCanvas.value.height = camera.value?.clientHeight ?? '100%'

      const { width, height } = videoCanvas.value
      videoCanvas.value.width = width * window.devicePixelRatio
      videoCanvas.value.height = height * window.devicePixelRatio

      videoCanvas.value.style.width = `${width}px`
      videoCanvas.value.style.height = `${height}px`

      // * Step 2: Load faceView
      await promiseTimeout(1000)
      const cameraNote = camera.value.getRootNode()
      faceView = new FaceView(canvasId, cameraNote)

      // * Step 2.1: Sound Listener
      soundListener()

      // * Step 3: Load captureValidatorView
      await faceView.start((faceInfo) => {
        if (!faceInfo || !captureValidatorView)
          return resetDefault()
        if (captureValidatorView.isValidFace(faceInfo)) {
          state.firstValidFace = true
          state.validFace = true
          playSoundBy('sound42')
        }
        else {
          state.validFace = false
          playSoundBy('sound41')
        }

        if (faceInfo.haveCard) {
          state.tick += 1
          state.holdCard = true

          if (state.tick > 20)
            state.measuring = true
        }
        else {
          if (state.tick !== 0) {
            // do nothing
            state.measuring = true
            state.tick = 0
          }
          state.holdCard = false
        }

        if (captureValidatorView.validate(faceInfo, false)) {
          playSoundBy('capture')
          requestAnimationFrame(() => {
            faceView?.stop()
          })
          return handleValidFace(videoCanvas.value)
        }
        captureValidatorView.visual(faceInfo)
        // console.log('Face is not valid')
      })

      captureValidatorView = new CatureValidatorView(videoCanvas.value, cameraNote)
    }
    catch (error) {
      consola.error('Measurement Error:::', { error })
    }
    finally {
      loadingStore.stopLoading()
    }
  }, DEBOUNCE_TIME)

  const load = debounce((isScriptLoaded: boolean) => {
    if (!isScriptLoaded)
      return
    loadMeasurement()
  }, DEBOUNCE_TIME, { leading: true })

  async function handleValidFace(videoCanvas: HTMLCanvasElement) {
    state.getPd = true

    if (!faceView)
      return
    faceInfo = await faceView.findFaceInfoEx()
    // consola.info('faceInfo', faceInfo)

    // * Face is Valid
    const formData = new FormData()
    formData.append(
      'face_pos',
      `${faceInfo.facePos.x},${faceInfo.facePos.y}`,
    )
    formData.append(
      'uri_image',
      videoCanvas.toDataURL('image/jpeg'),
    )

    // appStore.updatePdMeasureImage(videoCanvas.toDataURL('image/jpeg'))

    return await execute(0, formData)
  }

  function resetDefault() {
    goTo('measure')
    Object.assign(state, initialState)
    appStore.resetPdMeasure()
  }

  function playSoundBy(source: StringWithAutocompleteOptions<'sound41' | 'sound42' | 'sound43' | 'capture'>) {
    if (source === 'sound41') {
      if (sound41.seek() === 0 && !state.waiting) {
        stopSound()
        sound41.play()
        state.waiting = true
        return
      }
    }
    if (source === 'sound42') {
      if (sound42.seek() === 0 && !state.waiting) {
        stopSound()
        sound42.play()
        state.waiting = true
        return
      }
    }
    if (source === 'sound43') {
      stopSound()
      sound43.play()
      return
    }
    if (source === 'capture') {
      stopSound()
      soundCapture.play()
    }
  }

  function soundListener() {
    function setWaiting() {
      state.waiting = false
    }

    sound41.on('end', () => {
      setTimeout(setWaiting, SOUND_TIME_OUT)
    })
    sound42.on('end', () => {
      setTimeout(setWaiting, SOUND_TIME_OUT)
    })
    sound43.on('end', () => {
      setTimeout(setWaiting, SOUND_TIME_OUT)
    })
  }

  function stopSound() {
    sound41.stop()
    sound42.stop()
    sound43.stop()
    soundCapture.stop()
  }

  function retake() {
    resetDefault()
    load(true)
  }

  function stop(force = false) {
    if (!force || state.finish)
      return
    stopSound()
    faceView?.stop?.()
  }

  function garbageCollect() {
    stop(true)
    faceView = null
    captureValidatorView = null
  }

  return {
    ...toRefs(state),
    load,
    camera,
    videoCanvas,
    stop,
    faceValidColor,
    cardValidColor,
    holdCardColor,
    retake,
    current,
    goToNext,
    goTo,
    isCurrent,
    garbageCollect,
  }
}
