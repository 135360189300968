<script setup lang='ts'>
import sizeIcon from '@/assets/icons/sizing.svg?raw'
import liveIcon from '@/assets/icons/live.svg?raw'
import view360Icon from '@/assets/icons/view_360.svg?raw'
import frameIcon from '@/assets/icons/frame.svg?raw'
import lensIcon from '@/assets/icons/lens.svg?raw'
import checkIcon from '@/assets/icons/check.svg?raw'
import faceIcon from '@/assets/icons/face.svg?raw'
import stickCardIcon from '@/assets/icons/stick_card.svg?raw'
import holdIcon from '@/assets/icons/hold.svg?raw'
import firstGuidingIcon from '@/assets/icons/guide_1.svg?raw'
import secondGuidingIcon from '@/assets/icons/guide_2.svg?raw'
import thirdGuidingIcon from '@/assets/icons/guide_3.svg?raw'
import faceWidthIcon from '@/assets/icons/face_width_icon.svg?raw'
import innerCanthalIcon from '@/assets/icons/inner_canthal_icon.svg?raw'
import noseBridgeIcon from '@/assets/icons/nose_bridge_icon.svg?raw'
import pdIcon from '@/assets/icons/pd_icon.svg?raw'

interface IProps {
  name: StringWithAutocompleteOptions<'sizing' | 'live' | '360' | 'frame' | 'lens' | 'check' | 'face' | 'stick_card' | 'hold' | 'firstGuiding' | 'secondGuiding' | 'thirdGuiding' | 'faceWidth' | 'innerCanthal' | 'noseBridge' | 'pd'>
}

const props = withDefaults(defineProps<IProps>(), {
  name: 'sizing',
})

const iconsRender = computed(() => {
  const object = {
    sizing: sizeIcon,
    live: liveIcon,
    360: view360Icon,
    frame: frameIcon,
    lens: lensIcon,
    check: checkIcon,
    face: faceIcon,
    stick_card: stickCardIcon,
    hold: holdIcon,
    firstGuiding: firstGuidingIcon,
    secondGuiding: secondGuidingIcon,
    thirdGuiding: thirdGuidingIcon,
    faceWidth: faceWidthIcon,
    innerCanthal: innerCanthalIcon,
    noseBridge: noseBridgeIcon,
    pd: pdIcon,
  } as AnyObject
  return object?.[props.name] || object.pricing
})
</script>

<template>
  <QIcon size="xs">
    <div v-html="iconsRender" />
  </QIcon>
</template>
