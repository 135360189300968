import { createApp, defineCustomElement, getCurrentInstance, h } from 'vue'
import type { App, Component, ComponentInternalInstance } from 'vue'

import {
  convertToOnEventName,
  cssCompiler,
  modifyRoot,
  styleLoader,
} from './build'

export type Data = Record<string, unknown> | null

export type PluginOption = Record<any, any>

export interface TPlugins {
  install: (app: App) => void
}

export type instanceProvides = ComponentInternalInstance & { provides: Record<any, any> }

export type TComponent = Component & {
  props?: Record<string, any>
  emits?: string[]
  styles?: string[]
  namedSlots?: string[]
  provide?: () => AnyObject | AnyObject
  setup: (props: AnyObject, ctx: AnyObject) => void
}

export interface TCreateCEApp {
  rootComponent: TComponent
  cssFrameworkStyles: string
  elementName: string
  plugins: TPlugins
}
// TODO: support ssr ？
export function createCEApp({
  rootComponent,
  cssFrameworkStyles,
  elementName,
  plugins,
}: TCreateCEApp) {
  return defineCustomElement({
    // styles: [modifyRoot(styleLoader(cssFrameworkStyles).join(''))],
    props: {
      ...rootComponent.props,
    },
    emits: rootComponent?.emits,
    setup(props /* ctx */) {
      const emitsList = [...(rootComponent?.emits || [])]
      const app: App = createApp(rootComponent)
      app.component('AppRoot', rootComponent)
      //* Install plugins
      app.use(plugins)
      // * Setup global styles
      cssCompiler(modifyRoot(styleLoader(cssFrameworkStyles).join('')))
      if (rootComponent.provide) {
        const provide = typeof rootComponent.provide === 'function'
          ? rootComponent.provide()
          : rootComponent.provide
        // Setup provide
        Object.keys(provide).forEach((key) => {
          app.provide(key, provide[key])
        })
      }

      const inst = getCurrentInstance()
      if (inst) {
        Object.assign(inst.appContext, app._context)
        Object.assign((inst as instanceProvides).provides, app._context.provides)
      }

      // ? Forward all emitted events to the custom element
      const eventListeners = emitsList?.reduce((acc, eventName) => {
        const onEventName = convertToOnEventName(eventName)
        acc[onEventName] = (e: AnyObject) => inst?.emit(eventName, e)
        return acc
      }, {})

      // ? Establish named slots
      // const namedSlots = rootComponent?.namedSlots?.reduce((acc: AnyObject, slotsName) => {
      //   acc[slotsName] = () => h('slot', { name: slotsName })
      //   return acc
      // }, {})

      // ? Establish exposed methods
      // rootComponent.setup(props, ctx)

      // * Add support for Vue Devtools
      if (process.env.NODE_ENV === 'development' && (window as any).__VUE_DEVTOOLS_GLOBAL_HOOK__) {
        const root = document.querySelector(elementName)
        app._container = root
        app._instance = inst

        const types = {
          Comment: Symbol('v-cmt'),
          Fragment: Symbol('v-fgt'),
          Static: Symbol('v-stc'),
          Text: Symbol('v-txt'),
        };

        (window as any).__VUE_DEVTOOLS_GLOBAL_HOOK__.emit('app:init', app, app.version, types);
        (window as any).__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app
      }

      return () => h(
        rootComponent,
        {
          ...props,
          ...eventListeners,
        },
        // {
        //   default: () => h('slot'),
        //   ...namedSlots,
        // },
      )
    },
  })
}
