import { debounce } from 'perfect-debounce'

export function addWhen(condition: any, value: any) {
  if (Array.isArray(value))
    return condition ? value : []
  return condition ? [value] : []
}

/**
 * @use addObjectWhen(true, { a: 1 }) => { a: 1 }
 * @param condition     The condition is Truthy
 * @param value         The value to return when the condition is Truthy
 * @returns             Return an empty object when the condition is Falsy
 */
export function addObjectWhen(condition: any, value: any) {
  return condition ? value : {}
}
export function format(first: string, middle: string, last: string): string {
  return (first || '') + (middle ? ` ${middle}` : '') + (last ? ` ${last}` : '')
}

export function parseTruthy(value: string): value is 'true' {
  return value === 'true'
}

export function dataURItoBlob(dataURI: string) {
  const bytes = dataURI.split(',')[0].includes('base64') ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1])
  const mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const max = bytes.length
  const ia = new Uint8Array(max)
  for (let i = 0; i < max; i++) {
    ia[i] = bytes.charCodeAt(i)
  }
  return new Blob([ia], { type: mime })
}

export function resizeImage(blob: Blob): Promise<any> {
  const maxSizeVertical = 480
  const maxSize = 720
  const image = new Image()
  const canvas = document.createElement('canvas')
  const resize = function () {
    let width = image.width
    let height = image.height
    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width
        width = maxSize
      }
    }
    else {
      if (height > maxSizeVertical) {
        width *= maxSizeVertical / height
        height = maxSizeVertical
      }
    }
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')
    ctx.translate(width, 0)
    ctx.scale(-1, 1)
    ctx.drawImage(image, 0, 0, width, height)
    const dataUrl = canvas.toDataURL('image/png')
    return {
      blob: dataURItoBlob(dataUrl),
      scale: image.width / width,
    }
  }
  return new Promise((resolve) => {
    image.onload = function () {
      resolve(resize())
    }
    image.src = URL.createObjectURL(blob)
  })
}

export function disableImgEventHandlers(img: HTMLElement) {
  const events = ['onclick', 'onmousedown', 'onmousemove', 'onmouseout', 'onmouseover', 'onmouseup', 'ondblclick', 'onfocus', 'onblur']

  events.forEach((event) => {
    img[event] = function () {
      return false
    }
  })
}

function rgbToYIQ({ r, g, b }) {
  return ((r * 299) + (g * 587) + (b * 114)) / 1000
}

function hexToRgb(hex) {
  if (!hex || hex === undefined || hex === '') {
    return undefined
  }

  const result
        = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  return result
    ? {
        r: Number.parseInt(result[1], 16),
        g: Number.parseInt(result[2], 16),
        b: Number.parseInt(result[3], 16),
      }
    : undefined
}

export function stringToRGB(str?: string) {
  if (!str)
    return new THREE.Vector4(0, 0, 0, 0)
  const rgba = str?.split(',')
  const color = rgba && new THREE.Vector4(+rgba[0], +rgba[1], +rgba[2], +rgba[3])
  return color
}

export function hexToRgbA(hex: string, alpha: string | number) {
  let c
  if (/^#(?:[A-F0-9]{3}){1,2}$/i.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3)
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]

    c = `0x${c.join('')}`
    const r = (c >> 16) & 255
    const g = (c >> 8) & 255
    const b = c & 255
    return new THREE.Vector4(r / 255, g / 255, b / 255, +alpha)
  }
  throw new Error('Bad Hex')
}

export function generateContrastText(colorHex, threshold = 128) {
  if (colorHex === undefined) {
    return '#000'
  }

  const rgb = hexToRgb(colorHex)

  if (rgb === undefined) {
    return '#000'
  }

  return rgbToYIQ(rgb) >= threshold ? '#000' : '#fff'
}

export function transformColor(rgbColor?: string, withAlpha = '') {
  if (!rgbColor)
    return '0,0,0,0'
  const [r, g, b, a] = rgbColor.split(',')
  return `${+r * 255},${+g * 255},${+b * 255},${withAlpha || a}`
}

// export function mobileCheck() {
//   let check = false;
//   (function (a) {
//     if (/(?:android|bb\d|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(?:hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([\- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ \-/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([\- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[23]|n30(0|2)|n50([025])|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8c]))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([\-01])|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4)))
//       check = true
//   })(navigator.userAgent || navigator.vendor)
//   return check
// };

// export function getDefaultZoom() {
//   // Mobile: 0.35, Desktop: 0.55
//   return mobileCheck() ? 0.1 : 0.55
// }

export function uuidv7() {
  // random bytes
  const value = new Uint8Array(16)
  crypto.getRandomValues(value)

  // current timestamp in ms
  const timestamp = BigInt(Date.now())

  // timestamp
  value[0] = Number((timestamp >> 40n) & 0xFFn)
  value[1] = Number((timestamp >> 32n) & 0xFFn)
  value[2] = Number((timestamp >> 24n) & 0xFFn)
  value[3] = Number((timestamp >> 16n) & 0xFFn)
  value[4] = Number((timestamp >> 8n) & 0xFFn)
  value[5] = Number(timestamp & 0xFFn)

  // version and variant
  value[6] = (value[6] & 0x0F) | 0x70
  value[8] = (value[8] & 0x3F) | 0x80

  return value
}
