<script setup lang='ts'>
import { get } from 'radash'

const { appStore } = useStores()
const currentScreen = defineModel({ required: true, default: 'home' })

function goToHome() {
  currentScreen.value = 'home'
}

function goToMeasurement() {
  currentScreen.value = 'measurement'
}

const renderResults = computed(() => [
  {
    title: 'Face',
    icon: 'faceWidth',
    value: get<number>(appStore.values, 'pd.faceWidth'),
  },
  {
    title: 'PD',
    icon: 'innerCanthal',
    value: get<number>(appStore.values, 'pd.pd'),
  },
])
</script>

<template>
  <div v-show="currentScreen === 'measurement'" class="flex-1">
    <QBtn dense flat icon="keyboard_backspace" no-caps text-color="black" label="Back" color="primary" @click="goToHome" />
  </div>

  <section v-show="currentScreen === 'home'" class="flex flex-1">
    <div v-if="appStore.hasPdMeasure" class="flex items-center gap-6">
      <div v-for="(result, index) in renderResults" :key="index" class="row items-center gap-2">
        <div class="column" :class="index === 0 ? 'items-start' : 'items-center'">
          <CommonIcon :name="result.icon" class="!h-[24px] !w-[38px] !justify-start" />
          <QItemLabel class="text-sm text-white">
            {{ result.title }}
          </QItemLabel>
        </div>
        <QItemLabel class="text-h5 text-weight-bold text-white">
          {{ result.value }}
        </QItemLabel>
      </div>

      <QBtn padding="xs sm" icon="sync" color="white" no-caps outline label="Retake" @click="goToMeasurement" />
    </div>

    <QBtn v-else color="white" class="q-ml-xs" no-caps outline @click="goToMeasurement">
      <CommonIcon class="scale-[60%]" name="sizing" />
      <span class="q-ml-sm">Measure Face Size</span>
    </QBtn>
  </section>
</template>
