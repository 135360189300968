import { inject } from 'vue'
import { dialogInjectionKey } from 'gitart-vue-dialog'
import apis from '@/configs/api.config'

export function useEnhancer() {
  const $q = useQuasar()
  // 0 - 755 <-->756-1199 <-->1200
  const isSmallMobile = computed(() => $q.screen.width <= 360)
  const isMobile = computed(() => $q.screen.width <= 755)
  const isIpad = computed(() => $q.screen.width > 755 && $q.screen.width <= 1199)
  const isDesktop = computed(() => $q.screen.width > 1199)
  const isReallyMobile = computed(() => $q.platform.is.mobile)
  const deviceWidth = computed(() => $q.screen.width)
  const deviceHeight = computed(() => $q.screen.height)
  const isItReallyMobile = computed(() => {
    return (isMobile.value || isSmallMobile.value) && $q.platform.is.mobile
  })

  const cdnUrl = (path: string) => {
    const isUri = path?.startsWith('https') || path?.startsWith('http')
    return isUri ? path : `${apis.CDN_URL}/${path || ''}`
  }

  const getDefaultZoom = computed(() => {
    if (isMobile.value)
      return 0.1
    if (isIpad.value)
      return 0.4
    return 0.55
  })

  const getEmbeddedDefaultZoom = computed(() => {
    if (isMobile.value)
      return 0.35
    if (isIpad.value)
      return 0.4
    return 0.45
  })

  const strategyZoomBehavior = computed(() => {
    if (isMobile.value)
      return ['zoomOut']
    if (isIpad.value)
      return ['zoomIn']
    return ['zoomIn']
  })

  const pageTweak = (/* _, height: number */) => {
    // "offset" is a Number (pixels) that refers to the total
    // height of header + footer that occupies on screen,
    // based on the QLayout "view" prop configuration

    // this is actually what the default style-fn does in Quasar
    return {
      'height': '100%', /* `${height}px` */
      'max-height': '100%',
    }
  }

  return {
    $dialog: inject(dialogInjectionKey),
    pageTweak,
    isMobile,
    isIpad,
    isDesktop,
    isReallyMobile,
    deviceWidth,
    deviceHeight,
    isItReallyMobile,
    getDefaultZoom,
    strategyZoomBehavior,
    cdnUrl,
    getEmbeddedDefaultZoom,
  }
}
