// import type { API } from '@/app/app.types'

import type { TGroup, TLayout } from '@/app/app.types'

class GlassonApis {
  static async layoutSettings(id: string | number): Promise<TLayout> {
    return await $api(`/LayoutSettings/load?u=${id}`, {
      method: 'GET',
    })
  }

  static async groupById(id: string | number): Promise<TGroup> {
    return await $api(`/Groups/${id}`, {
      method: 'GET',
    })
  }

  static async tryOnLogTime(productId: string, logTime: number): Promise<AnyObject> {
    return await $api('/Products/tryOnLogTime?isMobile=false', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: { productId, logTime },
    })
  }

  static async recordApiUsage(userId: string, type: StringWithAutocompleteOptions<'vto' | '360' | 'pd'>): Promise<AnyObject> {
    return await $api(`/ApiUsages/record/${userId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: { type },
    })
  }

  static async tickAttention(userId: string) {
    return await $api(`/Analytics/tickAttention?u=${userId}`, {
      method: 'POST',
    })
  }

  static async tickIgnore(userId: string) {
    return await $api(`/Analytics/tickIgnore?u=${userId}`, {
      method: 'POST',
    })
  }
}

export default GlassonApis
