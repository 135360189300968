<script setup lang='ts'>
import { uid } from 'quasar'
import { promiseTimeout } from '@vueuse/core'
import { DEBOUNCE_TIME, useTryOnConfig } from '../try-on.model'

interface IProps {
  isBack?: boolean
}

withDefaults(defineProps<IProps>(), {
  isBack: false,
})

const canvasId = `jeeFaceFilterCanvas-${uid()}`
const { coreScriptStore, appStore, loadingStore } = useStores()
const focused = useWindowFocus()

const { load, container, glassesCanvasEl, applyUserPD, garbageCollect, enableGlassesRemoval, runStopWatch } = useTryOnConfig(canvasId)
const { pressed } = useGesture(glassesCanvasEl)

// TODO: Call load function when the app is embedded
watch([
  () => appStore.isAppEmbedded,
  () => coreScriptStore.isScriptsLoaded,
], () => {
  if (appStore.isAppMeasure)
    return
  if (appStore.isAppEmbedded)
    load(coreScriptStore.isScriptsLoaded, appStore.isTryOn)
}, { immediate: appStore.isAppEmbedded })

watch([
  () => appStore.isTryOn,
  () => appStore.isOpenDialog,
  () => coreScriptStore.isScriptsLoaded,
], async () => {
  loadingStore.startLoading()
  // Sleep 100ms: Avoid calling load function twice
  await promiseTimeout(DEBOUNCE_TIME)
  await garbageCollect()
  load(coreScriptStore.isScriptsLoaded, appStore.isTryOn)
}, { immediate: !appStore.isAppEmbedded })

watch(() => appStore.values.pd.pd, () => {
  applyUserPD(appStore.isTryOn)
})

watch(() => appStore.hasEnableGlassesRemoval, () => {
  enableGlassesRemoval(appStore.isTryOn)
})

watch(focused, (val) => {
  runStopWatch(!val ? 'pause' : 'resume')
})

onBeforeUnmount(() => {
  garbageCollect()
})
</script>

<template>
  <section class="fit go-main absolute-top-left bg-main transition">
    <div v-if="appStore.isTryOn" ref="container" class="fit">
      <canvas :id="canvasId" ref="glassesCanvasEl" />
      <video id="video" playsinline class="hidden" />
      <div class="fit fixed" style="visibility: hidden;">
        <!-- <canvas id="captureCanvas" ref="captureCanvasEl" /> -->
        <canvas id="image" style="display: none" />
      </div>
    </div>
    <div
      v-else
      ref="container"
      class="fit"
    >
      <!-- <canvas id="captureCanvas" ref="captureCanvasEl" style="visibility: hidden" /> -->
      <canvas :id="canvasId" ref="glassesCanvasEl" class="z-vr r360 absolute-center transform-gpu transition" :class="[pressed ? 'cursor-grabbing' : 'cursor-grab']" :style="isBack && 'transform: translate(-50%, -65%);'" />
    </div>
  </section>
</template>
