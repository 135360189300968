<script setup lang='ts'>
interface Props {
  hideClose?: boolean
}
withDefaults(defineProps<Props>(), {
  hideClose: false,
})
const emits = defineEmits(['close'])

const { appStore } = useStores()
</script>

<template>
  <QToolbar class="fixed-top-left full-width z-top text-white !border-transparent !border-none" :class="{ 'bg-gradient': appStore.isTryOn }">
    <slot />
    <QBtn v-if="hideClose" round dense icon="close" text-color="black" color="primary" @click="emits('close')" />
  </QToolbar>
</template>
