<script setup lang='ts'>
import { MeasureWidget } from '@/features/measure'

const { appStore } = useStores()
function onClose() {
  appStore.setOpenDialog(false)
}
</script>

<template>
  <LayoutDefault>
    <template #default>
      <QCard flat class="fit relative-position">
        <CommonHeader :hide-close="!appStore.isAppEmbedded" @close="onClose">
          <div class="flex-1" />
        </CommonHeader>
        <MeasureWidget @close="onClose" />
      </QCard>
    </template>
  </LayoutDefault>
</template>
