import { useMousePressed } from '@vueuse/core'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'

export function useGesture(canvasRef: Ref<HTMLCanvasElement> /* container: Ref<HTMLElement> */) {
  const { pressed } = useMousePressed({ target: canvasRef })

  // ? Disable viewport pinch zoom on whole app
  // const cancelEvent = (e: Event) => e.preventDefault();

  // const toggleEventDefault = (onTouch = false) => {
  //   if (onTouch) {
  //     document.addEventListener('gesturestart', cancelEvent, { passive: false });
  //     document.addEventListener('gesturechange', cancelEvent, { passive: false });
  //     window.addEventListener('wheel', cancelEvent, { passive: false });
  //   } else {
  //     document.removeEventListener('gesturestart', cancelEvent);
  //     document.removeEventListener('gesturechange', cancelEvent);
  //     window.removeEventListener('wheel', cancelEvent);
  //   }
  // }

  // watch(pressed, (value) => {
  //   if (value)
  //     enableBodyScrollLock()
  //   else
  //     clearAllBodyScrollLocks()
  // })

  return {
    pressed,
    // toggleEventDefault
  }
}

export function useBodyScrollLock() {
  const enableBodyScrollLock = () => {
    disableBodyScroll(document.body)
  }

  return {
    enableBodyScrollLock,
    clearAllBodyScrollLocks,
  }
}
