<script setup lang='ts'>
const [isActive, toggleActive] = useToggle(true)
const { appStore } = useStores()
const hasShow = computed(() => {
  return appStore.renderProducts.length > 1 && appStore.renderLens.length > 1
})
</script>

<template>
  <section class="fit flex items-end">
    <div class="full-width flex flex-1 items-end gap-2">
      <div v-if="hasShow" class="widget" :class="[{ active: isActive }]">
        <div class="menu">
          <div class="toggle" @click="toggleActive(!isActive)">
            <i v-for="item in appStore.renderMenusNav" :key="item.id" />
          </div>
          <ul class="list">
            <li v-for="tab in appStore.renderMenusNav" :key="tab.id" class="navigation__item" :class="[{ active: appStore.currentActiveFrameTab(tab.id) }]" @click="appStore.setActiveFrameTab(tab.id)">
              <a class="navigation__link" :class="[{ active: appStore.currentActiveFrameTab(tab.id) }]" href="javascript:;">
                <CommonIcon class="navigation__icon" size="42px" :name="tab.id" />
                <span class="navigation__text">{{ tab.label }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <slot :is-active="isActive" />
    </div>
    <slot name="caption" />
  </section>
</template>
