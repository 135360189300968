<script setup lang="ts">
import { get } from 'radash'
import { generateContrastText, parseTruthy } from './shared/common.utils'
import type { TRootState } from './app/app.types'
import { rootElement } from './@core/build'
import { META } from './configs/app.config'
import { TheBlankApp, TheFullApp, ThePDApp } from '@/app'
import { DEFAULT_ACTIVE_ROOT_TAB } from '@/app/app.lib'
import { OutputEvent } from '@/app/app.types'

interface IProps {
  embedded?: string
  zoom?: string
  userId?: string
  groupId?: string
  zIndex?: string
  screen?: TRootState['screen']
  mode?: string
  open?: string
  hasMeasure?: string
  hasRemoval?: string
}

const props = withDefaults(defineProps<IProps>(), {
  userId: '',
  groupId: '',
  embedded: '',
  zoom: '',
  zIndex: '99999',
  screen: 'home',
  mode: DEFAULT_ACTIVE_ROOT_TAB,
  open: '',
  hasMeasure: '',
  hasRemoval: '',
})

const emits = defineEmits(Object.values(OutputEvent))
const { getDefaultZoom } = useEnhancer()
const { enableBodyScrollLock, clearAllBodyScrollLocks } = useBodyScrollLock()
const cameraAccess = usePermission('camera')
const { appStore, loadingStore } = useStores()
const { isOpenDialog } = storeToRefs(appStore)

const hostData = computed(() => {
  return {
    style: {
      '--q-primary-color': get(appStore, 'layoutSettings.tintColor', ''),
      '--glasson-primary-color': get(appStore, 'layoutSettings.tintColor', ''),
      '--glasson-dialog-index': props.zIndex,
      '--glasson-typography-font-family': get(appStore, 'layoutSettings.font', ''),
      '--glasson-text-color': generateContrastText(get(appStore, 'layoutSettings.tintColor', '')),
    },
  }
})

const renderApp = computed(() => {
  const register = {
    home: TheFullApp,
    measurement: ThePDApp,
    default: TheBlankApp,
  }
  return isOpenDialog ? (register[appStore.values.screen] ?? register.default) : register.default
})

const shouldRenderDialog = computed(() => {
  return !parseTruthy(props.embedded) || !(props.userId && props.groupId)
})

/* ============== Watch Props ============== */
watch([
  () => props.userId,
  () => props.groupId,
], ([
  initialUserId,
  initialGroupId,
], [
  _,
  previousGroupId,
]) => {
  if (initialGroupId !== previousGroupId) {
    // Clear selection frame & len when group-id changes to avoid double loading of GlassOn
    appStore.setSelectionFrame(null)
    appStore.setSelectionLen(null)
  }
  // Ensure that app shows a loading state while appStore.initialLoad is making network calls
  loadingStore.startLoading()
  appStore.initialLoad({
    userId: initialUserId,
    groupId: initialGroupId,
    zoom: Number(props.zoom) || getDefaultZoom.value,
    mode: props.mode,
  })
}, { flush: 'pre', immediate: true })

watch([
  () => props.embedded,
  () => props.screen,
  () => props.open,
  () => props.mode,
], ([
  initialAppEmbedded,
  initialScreen,
  initialOpen,
  initialMode,
]) => {
  appStore.setAppEmbed(parseTruthy(initialAppEmbedded))
  appStore.setAppScreen(initialScreen)
  appStore.setActiveRootTab(initialMode)
  if (initialOpen)
    appStore.setOpenDialog(parseTruthy(initialOpen))
}, { flush: 'pre', immediate: true })

watch([
  () => props.zoom,
  () => props.hasMeasure,
  () => props.hasRemoval,
], ([
  initialZoomLevel,
  initialHasMeasure,
  initialHasRemoval,
]) => {
  appStore.setDefaultZoom(Number(initialZoomLevel) || getDefaultZoom.value)
  if (initialHasMeasure)
    appStore.setEnablePdMeasurement(parseTruthy(initialHasMeasure))
  if (initialHasRemoval)
    appStore.setEnableGlassesRemoval(parseTruthy(initialHasRemoval))
}, { flush: 'post', immediate: true })
/* ============== End Watch Props ============== */

/* ============== Watch State ============== */
watch(isOpenDialog, (val) => {
  if (val)
    return enableBodyScrollLock()

  appStore.setOpenDialog(false)
  clearAllBodyScrollLocks()
  rootElement()?.removeAttribute('open')
  rootElement()?.removeAttribute('screen')
  emits(OutputEvent.closeModel)
}, { flush: 'post' })
/* ============== End Watch State ============== */

/* ============== Emits Event ============== */
watch(() => appStore.values.pd.pd, () => {
  const { pd, faceWidth, innerCanthalDistance, noseBridgeWidth } = appStore.values.pd
  emits(OutputEvent.pdResult, {
    pd: appStore.hasPdMeasure ? pd : null,
    faceWidth,
    innerCanthalDistance,
    noseBridgeWidth,
  })
})
/* ============== End Emits Event ============== */

/* ============== On Mounted ============== */
onMounted(() => {
  window.addEventListener('beforeunload', () => {
    appStore.logAttention(props.userId)
  })
})
/* ============== End On Mounted ============== */
</script>

<template>
  <div :class="META.appName" :style="hostData.style">
    <!-- :persistent="cameraAccess === 'granted'" -->
    <DialogBase v-if="shouldRenderDialog" v-model="isOpenDialog">
      <CommonSplash :camera-access="cameraAccess">
        <component :is="renderApp" v-if="isOpenDialog" :key="renderApp.name" />
      </CommonSplash>
    </DialogBase>
    <CommonSplash v-else :camera-access="cameraAccess">
      <component :is="renderApp" :key="renderApp.name" />
    </CommonSplash>
  </div>
</template>
