import { consola } from 'consola'

export function roundNumberToOneDecimal(number: number) {
  const firstDecimal = Math.floor(number * 100) % 100
  let roundedNumber
  if (firstDecimal <= 25)
    roundedNumber = Math.floor(number)
  else if (firstDecimal <= 75)
    roundedNumber = Math.floor(number) + 0.5
  else
    roundedNumber = Math.ceil(number)

  return roundedNumber
}

export function toMM(number: number | string, mode: 'number' | 'html' = 'number') {
  return mode === 'html' ? `${roundNumberToDecimal(number)}<sub class="custom">mm</sub>` : `${roundNumberToDecimal(number)} mm`
}

export function roundNumberToDecimal(number: number | string, decimal = 1) {
  return Number(number).toFixed(decimal)
}

export function calculateScaleFit(faceWidth: number, interax: number) {
  return Number((faceWidth / interax))
}

export function round(number: number, precision: number = 0) {
  precision = precision === null ? 0 : (precision >= 0 ? Math.min(precision, 292) : Math.max(precision, -292))
  if (precision) {
    // Shift with exponential notation to avoid floating-point issues.
    // See [MDN](https://mdn.io/round#Examples) for more details.
    let pair = `${number}e`.split('e')
    const value = Math.round(`${pair[0]}e${+pair[1] + precision}`)

    pair = `${value}e`.split('e')
    return +`${pair[0]}e${+pair[1] - precision}`
  }
  return Math.round(number)
}

export { consola }
