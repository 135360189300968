<script setup lang='ts'>
import { GDialog } from 'gitart-vue-dialog'

interface Props {
  contrastStyle?: string
  contentClass?: string
  persistent?: boolean
  transition?: string
  width?: string
  removeContentWhenClose?: boolean
}

withDefaults(defineProps<Props>(), {
  contrastStyle: 'bg-black dialog-end relative-position text-white full-height',
  contentClass: 'dialog-width',
  persistent: false,
  transition: 'custom-from-top-transition',
  width: '100%',
  removeContentWhenClose: false,
})

const isShow = defineModel({ required: true, type: Boolean, default: false })
</script>

<template>
  <GDialog
    v-model="isShow"
    local
    :width="width"
    :transition="transition"
    :persistent="persistent"
    :contrast-style="contrastStyle"
    :content-class="contentClass"
  >
    <template #default="{ onClose }">
      <slot :on-close="onClose" />
    </template>
  </GDialog>
</template>
