<script setup lang='ts'>
import type { TLen } from '@/app/app.types'
import { transformColor } from '@/shared/common.utils'

const { appStore } = useStores()
const { cdnUrl } = useEnhancer()
const isMirrorLen = (item: TLen) => item?.sku?.toLowerCase().includes('mirror')

function bgMirrorColor(item: TLen) {
  return [
    {
      height: '50px',
      width: '50px',
      ...(item.texture
        ? {
            backgroundImage: `url(${cdnUrl(item.texture)})`,
            backgroundPosition: 'center',
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
          }
        : {
            background: `rgba(${transformColor(item.color, '1')})`,
          }),
    },
  ]
}
function bgColor(item: TLen) {
  return [
    {
      height: '50px',
      width: '50px',
      ...(item.texture
        ? {
            backgroundImage: `url(${cdnUrl(item.texture)})`,
            backgroundPosition: 'center',
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
          }
        : {
            background: `rgba(${transformColor(item.color)})`,
          }),
    },
  ]
}
</script>

<template>
  <QVirtualScroll
    v-if="appStore.renderLens.length > 1"
    v-slot="{ item }: { item: TLen }"
    :items="appStore.renderLens"
    virtual-scroll-horizontal
    class="flex-1 items-end"
  >
    <QItem
      v-ripple
      clickable
      class="q-pa-none q-mr-md q-ma-xs shadow-2 relative-position size-[50px] cursor-pointer rounded-full"
      @click="appStore.setSelectionLen(item)"
    >
      <div v-if="isMirrorLen(item)" class="relative-position row shadow-2 rounded-full">
        <div
          class="half-left rounded-full"
          :style="[bgMirrorColor(item)]"
        />
        <div
          class="half-right rounded-full"
          :style="[bgColor(item), { 'margin-left': '-50px' }]"
        />
        <div class="fit column absolute items-center justify-center">
          <CommonIcon v-if="appStore.shouldSelectedLenWith(item.id)" class="scale-75" name="check" />
        </div>
      </div>
      <div
        v-else
        class="column items-center justify-center rounded-full"
        :style="bgColor(item)"
      >
        <CommonIcon v-if="appStore.shouldSelectedLenWith(item.id)" name="check" class="scale-75" />
      </div>
    </QItem>
  </QVirtualScroll>
</template>
