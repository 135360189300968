import { INITIAL_SCRIPTS } from '@/configs/app.config'

export const NAMESPACE = 'app-tryon-script-module'
export const core = defineStore(NAMESPACE, () => {
  const { loadingStore } = useStores()
  const [isScriptsLoaded, setScriptsLoaded] = useToggle(false)
  // START: ========= Load scripts
  const tryOnCoreScript = useScriptTag(
    INITIAL_SCRIPTS.coreTryOn,
    () => {
      consola.info('TRY-ON CORE PRELOADED!')
    },
    {
      async: true,
      defer: true,
      manual: true,
    },
  )

  const hammerScript = useScriptTag(
    INITIAL_SCRIPTS.hammerLib,
    () => {
      consola.info('HAMMER PRELOADED!')
    },
    {
      type: 'module',
      async: true,
      defer: true,
      manual: true,
    },
  )

  const threeModuleScript = useScriptTag(
    INITIAL_SCRIPTS.threeModule,
    () => {
      consola.info('THREE MODULE PRELOADED!')
    },
    {
      type: 'module',
      async: false,
      defer: false,
      manual: true,
    },
  )

  const mainScript = useScriptTag(
    INITIAL_SCRIPTS.threeMap,
    async () => {
      try {
        loadingStore.startLoading()
        await threeModuleScript.load()
        await Promise.allSettled([
          tryOnCoreScript.load(),
          // openCVScript.load(),
          hammerScript.load(),
        ])
        consola.success('ALL TRY_ON PRELOADED!')
        setScriptsLoaded(true)
      }
      catch {
        loadingStore.stopLoading()
      }
      finally {
        // loadingStore.stopLoading()
      }
    },
    {
      // type: 'module',
      async: true,
      defer: true,
      manual: true,
    },
  )

  // const openCVScript = useScriptTag(
  //   INITIAL_SCRIPTS.openCV,
  //   () => {
  //     console.log('OPENCV PRELOADED!')
  //   },
  //   {
  //     // type: 'module',
  //     async: true,
  //     defer: true,
  //     manual: true,
  //   },
  // )

  function loadScripts() {
    !isScriptsLoaded.value && mainScript.load()
  }

  function uploadScript() {
    return Promise.allSettled([
      mainScript.unload(),
      threeModuleScript.unload(),
      tryOnCoreScript.unload(),
      hammerScript.unload(),
    ]).then(() => {
      setScriptsLoaded(false)
    })
  }
  // END: ========= Load scripts
  loadScripts()

  return {
    isScriptsLoaded,
    loadScripts,
    uploadScript,
  }
})

export const NAMESPACE_1 = 'app-measure-script-module'
export const measure = defineStore(NAMESPACE_1, () => {
  const [isScriptsLoaded, setScriptsLoaded] = useToggle(false)
  // START: ========= Load scripts
  const measureCoreScript = useScriptTag(
    INITIAL_SCRIPTS.measureCore,
    () => {
      consola.info('MEASURE CORE PRELOADED!')
    },
    {
      // type: 'module',
      async: true,
      defer: true,
      manual: true,
    },
  )

  const tensorFlowScript = useScriptTag(
    INITIAL_SCRIPTS.tensorFlow,
    () => {
      consola.info('TENSORFLOW PRELOADED!')
    },
    {
      // type: 'module',
      async: true,
      defer: true,
      manual: true,
    },
  )

  async function loadScripts() {
    if (!isScriptsLoaded.value) {
      await tensorFlowScript.load()
      await measureCoreScript.load()
      setScriptsLoaded(true)
      consola.success('ALL MEASURE PRELOADED!')
    }
  }

  function uploadScript() {
    return Promise.allSettled([
      measureCoreScript.unload(),
      tensorFlowScript.unload(),
    ]).then(() => {
      setScriptsLoaded(false)
    })
  }
  // END: ========= Load scripts
  loadScripts()

  return {
    isScriptsLoaded,
    loadScripts,
    uploadScript,
  }
})
