import * as Sentry from '@sentry/vue'
import { debounce } from 'perfect-debounce'
import { stringToRGB } from '@/shared/common.utils'
import GlassonApis from '@/shared/apis'
import { DELTA_FACE_WIDTH } from '@/configs/app.config'

export const DEBOUNCE_TIME = 100
export function useTryOnConfig(canvasId: string) {
  const { loadingStore, appStore } = useStores()
  const { currentSelectedFrame, currentSelectedLen, values: customizeData } = storeToRefs(appStore)
  const { cdnUrl, strategyZoomBehavior, getEmbeddedDefaultZoom } = useEnhancer()
  let glassesView: GlassesView | null = null
  let stopWatch: StopWatch | null = null

  const container = templateRef<HTMLElement>('container')
  const glassesCanvasEl = templateRef<HTMLCanvasElement>('glassesCanvasEl')

  const [isFirstLoad, setFirstLoad] = useToggle(true)

  const applyPartConfig = async (
    currentGlassesView: GlassesView | null,
    { frameType, lenColor }: AnyObject,
  ) => {
    if (!currentGlassesView)
      return
    try {
      // * Model
      if (frameType && currentSelectedFrame.value?.model) {
        loadingStore.startLoading()
        logTime()
        const modelUrl = cdnUrl(currentSelectedFrame.value?.model?.newZipUrl || currentSelectedFrame.value?.model?.zipUrl)
        const modelType = currentSelectedFrame.value.modelType === '3D' ? (currentSelectedFrame.value.isGLB ? '3D' : '25D') : '2D'
        await currentGlassesView.tryGlasses(modelUrl, modelType)
        prepareGlassesView(currentGlassesView)
        applyUserPD(appStore.isTryOn)
        runStopWatch('start')
      }

      // * Len
      if (lenColor && currentSelectedLen.value) {
        await setLenFilterValue(currentGlassesView, currentSelectedLen.value)
      }

      // * Render
      // await promiseTimeout(100)
      currentGlassesView.refresh()
      // consola.success('ApplyPartConfig')
    }
    catch (error) {
      consola.error('ApplyPartConfig', { error })
      Sentry.captureException(error)
    }
    loadingStore.stopLoading(appStore.isTryOn ? currentGlassesView : undefined)
  }

  const applyAllConfig = async (glassesView: GlassesView | null) => await applyPartConfig(glassesView, {
    frameType: true,
    lenColor: true,
  })

  const applyChange = debounce(
    async ({ /* name, store, after, onError */ name, args }) => {
      // * Wait utils lib loaded
      if (!glassesView)
        return

      const [data = {}] = args as AnyObject[]
      if (!data)
        return
      // console.log('applyChange:::', name, data)
      // * Apply specific part configuration
      if (name === 'setSelectionLen')
        return await applyPartConfig(glassesView, { lenColor: true })
      if (name === 'setSelectionFrame')
        return await applyAllConfig(glassesView)
    },
    DEBOUNCE_TIME,
  )

  function prepareGlassesView(currentGlassesView: GlassesView | null) {
    if (currentGlassesView && isFirstLoad.value) {
      if (currentGlassesView.controls) {
        currentGlassesView.controls.enableZoom = true
      }

      if (appStore.isAppEmbedded) {
        currentGlassesView?.zoomIn?.(getEmbeddedDefaultZoom.value)
        return
      }

      if (strategyZoomBehavior.value.includes('zoomOut')) {
        currentGlassesView?.zoomOut?.(appStore.values.defaultZoom)
      }
      else {
        currentGlassesView?.zoomIn?.(appStore.values.defaultZoom)
      }
      setFirstLoad(false)
    }
  }

  // const enableAutoFit = (rate = 1) => {
  //   if (!glassesView)
  //     return
  //   glassesView?.currentGlasses?.scaleByRate?.(rate)
  // }

  const enableGlassesRemoval = async (isTryOn: boolean) => {
    if (!glassesView || !isTryOn)
      return
    await glassesView?.enableGlassRemoval?.(appStore.hasEnableGlassesRemoval)
  }

  async function setLenFilterValue(currentGlassesView: GlassesView | null, data: AnyObject) {
    if (currentGlassesView && data) {
      let tex
      if (data.texture) {
        const texUrl = cdnUrl(data.texture)
        try {
          tex = await Utility.loadTextureAsync(texUrl)
        }
        catch {
          tex = await Utility.loadTextureAsync(`${texUrl}?${Math.random()}`)
        }
      }
      currentGlassesView?.currentGlasses?.setLensesFilter?.({
        color: stringToRGB(data.color),
        ...(tex && { tex }),
        reflection: data.reflection,
        brightness: data.brightness,
        contrast: data.contrast,
        saturation: data.saturation,
        sceneLens: true,
      })
    }
  }

  const applyUserPD = debounce(async (isTryOn: boolean) => {
    if (!glassesView || !isTryOn)
      return
    let faceWidth: number = customizeData.value.pd.faceWidth - DELTA_FACE_WIDTH
    const { pd, measurePd } = customizeData.value.pd

    // * In case user modify PD
    if (pd !== measurePd) {
      const newFaceWidth = await glassesView?.measureFaceWidth(pd)
      faceWidth = Math.round(newFaceWidth * 2) / 2
    }

    // * User has PD measure Apply real size
    if (appStore.values.group.glassesWidth && faceWidth > 0) {
      return glassesView?.currentGlasses.realsizeScale(appStore.values.group.glassesWidth, faceWidth)
    }
  }, DEBOUNCE_TIME)

  const logContext = useAsyncState(
    async (accountId, productId, logTime, type: StringWithAutocompleteOptions<'vto' | '360'>) => {
      await Promise.allSettled([
        GlassonApis.tryOnLogTime(productId, logTime),
        GlassonApis.recordApiUsage(accountId, type),
      ])
    },
    null,
    {
      immediate: false,
      onSuccess: () => {},
    },
  )

  const logTime = () => {
    const tryOnLogTime = stopWatch?.stop() ?? 0
    if (currentSelectedFrame.value?.id && tryOnLogTime > 0) {
      logContext.execute(
        0,
        customizeData.value.layout.accountId,
        currentSelectedFrame.value.id,
        tryOnLogTime,
        appStore.isTryOn ? 'vto' : '360',
      )
    }
  }

  const initStopWatch = () => {
    if (!stopWatch) {
      stopWatch = new StopWatch()
    }
  }

  const runStopWatch = (action: StringWithAutocompleteOptions<'start' | 'stop' | 'pause' | 'resume'>) => {
    switch (action) {
      case 'start':
        stopWatch?.start()
        break
      case 'stop':
        stopWatch?.stop()
        break
      case 'pause':
        stopWatch?.pause()
        break
      case 'resume':
        stopWatch?.resume()
        break
      default:
    }
  }

  const garbageCollect = async () => {
    // consola.info('Garbage Collect')
    await glassesView?.stop?.()
    // captureView?.stop?.()
    setFirstLoad(true)
    logTime()
  }

  const loadGlassOn = async (isTryOn: boolean /* , shouldClear = true */) => {
    // shouldClear && garbageCollect()

    if (!glassesCanvasEl.value || !container.value)
      return
    glassesCanvasEl.value.width = container.value?.clientWidth ?? '100%'
    glassesCanvasEl.value.height = container.value?.clientHeight ?? '100%'

    // Step 1: Create glasses view
    glassesView = new (isTryOn ? TryOnView : GlassesView)(
      canvasId,
      container.value.getRootNode(),
    )

    // * Step 2: Start glasses view
    await glassesView.start()
    // renderCaptureView(isTryOn)

    // * Step 2.1: Set perfect fit
    await enableGlassesRemoval(isTryOn)

    // * Step 3: Apply configuration
    await applyAllConfig(glassesView)

    // * Step 4: Stop loading when apply configuration done
    loadingStore.stopLoading(isTryOn ? glassesView : undefined)
  }

  const load = debounce((isScriptLoaded: boolean, isTryOn: boolean) => {
    if (!isScriptLoaded)
      return
    initStopWatch()
    try {
      loadGlassOn(isTryOn)
    }
    catch (error) {
      loadingStore.stopLoading()
      consola.error('LoadGlassOn', { error })
      Sentry.captureException(error)
    }
  }, DEBOUNCE_TIME, { trailing: true })

  const unsubscribe = appStore.$onAction(applyChange)

  return {
    container,
    glassesCanvasEl,
    glassesView,
    loadGlassOn,
    garbageCollect,
    load,
    enableGlassesRemoval,
    applyUserPD,
    runStopWatch,
    unsubscribe,
    // handleSwitchVideoSource,
  }
}
