<script setup lang='ts'>
import type { TProduct } from '@/app/app.types'

const { appStore } = useStores()
const { cdnUrl } = useEnhancer()
function onSelectedFrame(item: TProduct) {
  if (!appStore.shouldSelectedFrameWith(item.id))
    appStore.setSelectionFrame(item)
}
</script>

<template>
  <KeepAlive>
    <QVirtualScroll
      v-if="appStore.renderProducts.length > 1"
      v-slot="{ item }: { item: TProduct }"
      :items="appStore.renderProducts"
      virtual-scroll-horizontal
      class="flex-1 items-end"
    >
      <div
        v-ripple
        class="relative-position rounded-borders q-mr-sm q-ma-xs q-pa-xs min-w-[78px] w-[21.5vw] flex cursor-pointer items-center justify-center bg-white md:w-[208px]"
        :class="{ border: appStore.shouldSelectedFrameWith(item.id) }"
        @click="onSelectedFrame(item)"
      >
        <div
          v-if="appStore.shouldSelectedFrameWith(item.id)"
          class="z-top mobile-hide absolute right-[8px] top-0 bg-transparent"
        >
          <CommonIcon class="scale-75" name="check" />
        </div>
        <QImg fit="contain" loading="eager" no-spinner class="q-px-md h-[35px]" :src="cdnUrl(item.model.photos)" :alt="item.sku" />
        <div class="text-body2 ellipsis text-nowrap">
          {{ item.sku }}
        </div>
      </div>
    </QVirtualScroll>
  </KeepAlive>
</template>
