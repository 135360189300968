/**
 * @file App config
 * @module config.app
 */

export const META = Object.freeze({
  appName: 'glasson-try-on',
})

export const INITIAL_LINKS = [
  {
    rel: 'preconnect',
    href: 'https://fonts.googleapis.com',
    as: 'font',
  },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    as: 'font',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Prompt:wght@100;400;600;700&display=swap',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css?family=Material+Icons',
  },
  // ? Css reset
  // {
  //   rel: "stylesheet",
  //   href: "https://cdn.jsdelivr.net/gh/jgthms/minireset.css@master/minireset.min.css",
  // }
]

const isLegacy = new URLSearchParams(window.location.search).get('legacy') === 'true'
const isUpgraded = !isLegacy

export const INITIAL_SCRIPTS = {
  threeMap: import.meta.env.VITE_THREE_MAP,
  threeModule: import.meta.env.VITE_THREE_MODULE,
  hammerLib: import.meta.env.VITE_HAMMER,
  coreTryOn: isUpgraded ? import.meta.env.VITE_TRY_ON_CORE : import.meta.env.VITE_LEGACY_TRY_ON_CORE,
  measureCore: import.meta.env.VITE_MEASUREMENT_CORE,
  tensorFlow: import.meta.env.VITE_EXTERNAL_TENSORFLOW,
  openCV: import.meta.env.VITE_EXTERNAL_OPENCV,
}

export const CDN_ASSETS = {
  logo: 'https://d3lgac2qoojxa5.cloudfront.net/bffe09ef-db3f-447c-a504-79d7d034266e.gif',
  soundCapture: 'https://d3lgac2qoojxa5.cloudfront.net/hello-o/camera-shutter.mp3',
  sound41: 'https://d3lgac2qoojxa5.cloudfront.net/assets/pd-measurement-voice-instruction/put+your+face.mp3',
  sound42: 'https://d3lgac2qoojxa5.cloudfront.net/assets/pd-measurement-voice-instruction/place+the+card.mp3',
  sound43: 'https://d3lgac2qoojxa5.cloudfront.net/assets/pd-measurement-voice-instruction/check+result.mp3',
}

export const APP_VERSION = __APP_VERSION__

export const DELTA_FACE_WIDTH = 10 // 10cm
