import type { /* FetchContext, */ FetchOptions, FetchRequest/* , FetchResponse */ } from 'ofetch'
import { ofetch as $fetch } from 'ofetch'
import { defu } from 'defu'
import apiEndpoint from '@/configs/api.config'

export async function $api<T = unknown>(
  request: FetchRequest,
  options?: FetchOptions,
) {
  const headers: HeadersInit = {

  }

  const defaultConfig: FetchOptions = {
    baseURL: apiEndpoint.GLASSON,
    headers,
    credentials: 'include',
  }

  return $fetch<T>(
    request,
    defu(options, defaultConfig) as FetchOptions<'json'>,
  )
}
