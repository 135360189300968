import type { App } from 'vue'
import { createPinia } from 'pinia'
import { Quasar } from 'quasar'
// import { createI18n } from 'vue-i18n'
import Toast from 'vue-toastification'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import { parse, stringify } from 'zipson'
import { plugin as dialogPlugin } from 'gitart-vue-dialog'
import { META } from '@/configs/app.config'
import { asyncGetContainer } from '@/@core/build'

export const pluginsWrapper = {
  install(Vue: App) {
    // * Pinia
    const pinia = createPinia()
    Vue.use(pinia)
    pinia.use(createPersistedState({
      storage: window.localStorage,
      key: (id: string) => `__${META.appName}__${id}`,
      serializer: {
        deserialize: parse,
        serialize: stringify,
      },
    }))

    // * Quasar
    Vue.use(Quasar, {})

    // * Toast
    Vue.use(Toast, {
      container: asyncGetContainer,
    })

    // * Dialog
    Vue.use(dialogPlugin)

    // * I18n
    // const locales = import.meta.glob('/src/locales/*.yml', {
    //   query: '?raw',
    //   import: 'default',
    //   eager: true,
    // })
    // const messages = Object.fromEntries(
    //   Object.entries(locales).map(([key, value]) => {
    //     const mes = [jsYaml.load(value)].reduce((pre, cur) => {
    //       return { ...pre, ...cur }
    //     }, {})
    //     return [key.slice(13, -4), mes]
    //   }),
    // )
    // const i18n = createI18n({
    //   fallbackLocale: 'ja',
    //   locale: 'ja',
    //   messages,
    // })
    // Vue.use(i18n)

    // * Directive
    Vue.directive('hoist', (el) => {
      if (el.tagName === 'TEMPLATE')
        el.replaceWith(el.content)

      else
        el.replaceWith(...el.children)
    })
  },
}
