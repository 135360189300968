export const NAMESPACE = 'app-global-loading-module'

export const use = defineStore(NAMESPACE, () => {
  const [isLoading, setLoading] = useToggle(true)

  const startLoading = () => setLoading(true)
  const stopLoading = (tryOnView?: TryOnView) => {
    if (!tryOnView)
      return setLoading(false)
    tryOnView.onFirstFrameDidRender = () => setLoading(false)
  }

  return {
    isLoading,
    startLoading,
    stopLoading,
  }
})
