import * as Sentry from '@sentry/vue'
import { createCEApp } from './@core'
import { linksLoader } from './@core/build'
import { META } from './configs/app.config'
import { pluginsWrapper } from './plugins'
import RootApp from './RootApp.vue'
import { INITIAL_LINKS } from '@/configs/app.config'

import mainStyle from '@/styles/app.scss?inline'
import tailwind from '@/styles/tailwind.css?inline'

Sentry.init({
  dsn: 'https://df2bba855ffe2723ff9b9f271e58856a@o4507931963817984.ingest.us.sentry.io/4507932085190656',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

linksLoader(INITIAL_LINKS)
const rootCEComponent = createCEApp({
  rootComponent: RootApp,
  cssFrameworkStyles: `${tailwind}${mainStyle}`,
  elementName: META.appName,
  plugins: pluginsWrapper,
})

customElements.define(META.appName, rootCEComponent)
