import { appModel, loadingModel, scriptModel } from '@/app'

export function useStores() {
  return {
    appStore: appModel.use(),
    loadingStore: loadingModel.use(),
    coreScriptStore: scriptModel.core(),
    measureScriptStore: scriptModel.measure(),
  }
}
