<script setup lang='ts'>
import FrameList from './frame-list.vue'
import LensList from './lens-list.vue'
import MenuControl from './menu-control.vue'
import ModeControl from './mode-control.vue'

const { appStore } = useStores()
</script>

<template>
  <div v-if="!appStore.isEnterpriseCustomer" class="absolute-top-left q-pl-xs full-height w-auto flex justify-center">
    <div class="flex items-center">
      <QBtn dense flat padding="0" no-caps class="text-subtitle1 text-weight-bold [writing-mode:vertical-lr] rotate-180 text-white drop-shadow-md transition-all duration-300 hover:underline" href="https://glasson.io/" target="blank">
        GlassOn
      </QBtn>
    </div>
  </div>
  <div class="absolute-top-right q-pr-sm pt-[66px]">
    <ModeControl />
  </div>
  <div class="absolute-bottom full-width q-pa-sm relative-position overflow-hidden" :class="appStore.shouldShowDisclaimer ? 'h-[180px]' : 'h-[160px]'">
    <MenuControl>
      <template #default="{ isActive }">
        <Transition v-show="isActive" name="scale-slide" mode="out-in" :duration="150">
          <FrameList v-if="appStore.currentActiveFrameTab('frame')" />
          <LensList v-else />
        </Transition>
      </template>
      <template v-if="appStore.shouldShowDisclaimer" #caption>
        <div class="full-width text-grey-7 text-center text-sm drop-shadow-md">
          We won't store the image of your face
        </div>
      </template>
    </MenuControl>
  </div>
</template>
