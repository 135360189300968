import { sift } from 'radash'
import type { TGroup, TLayout, TRootState } from './app.types'

export const DEFAULT_ACTIVE_ROOT_TAB = 'live'
export const DEFAULT_ACTIVE_FRAME_TAB = 'frame'
export const DEFAULT_PD_SIZE = 64
function getDefaultLayout(): TLayout {
  return {
    logo: '',
    tintColor: '',
    font: '',
    showDescription: false,
    showLive: false,
    show360: false,
    showVideo: false,
    showFrames: false,
    showLens: false,
    showPD: false,
    showCTA: false,
    ctaBtn: {
      icon: '',
      text: 'add to cart',
      functionName: 'addToCard',
    },
    accountId: null,
    rootTabNav: [
      { label: 'Live', id: 'live' },
      { label: '360', id: '360' },
    ],
  }
}

function getDefaultGroup(): TGroup {
  return {
    name: '',
    filter: [],
    status: '',
    desc: '',
    glassesWidth: 0,
    lensOptions: [],
    id: '',
    product: [],
    lens: [],
    frameTabNav: [
      { label: 'Frame', id: 'frame' },
      { label: 'Lens', id: 'lens' },
    ],
    hasBuildInLens: false,
  }
}

export function getDefaultPD(pdPayload: TRootState['pd'] = {}): TRootState['pd'] {
  const { pd = 0, measurePd = 0, noseBridgeWidth = 0, faceWidth = 0, innerCanthalDistance = 0 } = pdPayload
  return {
    measurePd,
    pd,
    noseBridgeWidth,
    faceWidth,
    innerCanthalDistance,
  }
}

export function getDefaultActiveFrame(productLength = 1, lensLength = 1): string {
  if (productLength === 1 && lensLength > 1)
    return 'lens'
  if (productLength > 1 && lensLength === 1)
    return 'frame'
  return DEFAULT_ACTIVE_FRAME_TAB
  // if (productLength > 1 && lensLength > 1)
  //   return DEFAULT_ACTIVE_FRAME_TAB
  // if (productLength === 1 && lensLength === 1)
  //   return DEFAULT_ACTIVE_FRAME_TAB
}

export function defaultState(cacheData: TRootState = {}): TRootState {
  return {
    layout: getDefaultLayout(),
    group: getDefaultGroup(),
    currentSelectedFrame: null,
    currentSelectedLen: null,
    defaultActiveRootTab: DEFAULT_ACTIVE_ROOT_TAB,
    defaultActiveFrameTab: getDefaultActiveFrame(),
    isOpen: false,
    defaultZoom: 0,
    isAppEmbedded: false,
    screen: 'home',
    pd: getDefaultPD(cacheData.pd),
  }
}

export function transformLayoutSetting(layoutSettingData?: TLayout) {
  if (!layoutSettingData)
    return getDefaultLayout()
  return {
    ...layoutSettingData,
    rootTabNav: sift([
      layoutSettingData.showLive && { label: 'Live', id: 'live' },
      layoutSettingData.show360 && { label: '360', id: '360' }, //
    ]),
  }
}

export function transformGroupData(groupData: TGroup) {
  if (!groupData)
    return getDefaultGroup()
  const { lens, product } = groupData
  const hasBuildInLens = lens && lens.length > 1

  // ! Cheat <---------
  const modifyProducts = product.map((item) => {
    return {
      ...item,
      model: {
        ...item.model,
        photos: item?.model?.photos?.split(',')?.shift(),
      },
    }
  })
  // const onlyRenderFrames = product && product.length > 1

  return {
    ...getDefaultGroup(),
    ...groupData,
    hasBuildInLens,
    product: modifyProducts,
    frameTabNav: [
      { label: 'Frame', id: 'frame' },
      { label: 'Lens', id: 'lens' },
    ],
  }
}
