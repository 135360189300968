<script setup lang='ts'>
import { get } from 'radash'

const emits = defineEmits(['retake', 'close'])
const { appStore } = useStores()
const renderResults = computed(() => [
  {
    title: 'Face Width',
    icon: 'faceWidth',
    value: get<number>(appStore.values, 'pd.faceWidth'),
  },
  {
    title: 'Canthal Distance',
    icon: 'pd',
    value: get<number>(appStore.values, 'pd.innerCanthalDistance'),
  },
  {
    title: 'Bridge Width',
    icon: 'noseBridge',
    value: get<number>(appStore.values, 'pd.noseBridgeWidth'),
  },
  {
    title: 'Pupil Distance',
    icon: 'innerCanthal',
    value: get<number>(appStore.values, 'pd.pd'),
  },
])

const leftPd = computed(() => get<number>(appStore.values, 'pd.leftPd'))
const rightPd = computed(() => get<number>(appStore.values, 'pd.rightPd'))
</script>

<template>
  <QCard class="fit rounded-t-[16px] backdrop-blur-md !bg-black/50">
    <QItem class="flex items-center justify-between gap-x-3 !px-2">
      <div class="flex cursor-pointer items-center gap-2" @click="emits('retake')">
        <QBtn color="black" round icon="restart_alt" dense text-color="white" />
        <span class="text-body2 text-white">Retake</span>
      </div>
      <QItemLabel class="text-h6 text-center text-white">
        Result
      </QItemLabel>
      <div class="w-[80px]">
        <CommonMainButton label="OK" class="text-weight-bold text-body2" @click="emits('close')" />
      </div>
    </QItem>
    <div class="q-py-sm grid grid-cols-[70px_104px_82px_109px] justify-around gap-[2px] px-1 sm:grid-cols-[1fr_1.1fr_1fr_auto] md:gap-2 sm:gap-1 sm:px-3">
      <div v-for="(result, index) in renderResults" :key="index" class="column justify-between gap-y-2">
        <CommonIcon :name="result.icon" class="!h-[24px] !w-[38px] !justify-start" />
        <QItemLabel class="text-xs text-white">
          {{ result.title }}
        </QItemLabel>
        <QItemLabel class="text-primary text-h6 text-weight-bold flex items-end gap-1">
          {{ result.value }}
          <div class="inline gap-1 pb-[5px] text-xs">
            <span v-if="index === 3">({{ leftPd }}+{{ rightPd }})</span>
            <span class="text-white">mm</span>
          </div>
        </QItemLabel>
      </div>
    </div>
  </QCard>
</template>
