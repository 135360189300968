<script setup lang="ts">
import { CDN_ASSETS } from '@/configs/app.config'

const { appStore, loadingStore, coreScriptStore, measureScriptStore } = useStores()
const { pageTweak } = useEnhancer()

const hasLoading = computedAsync(() => {
  return (appStore.isAllLoading && (!coreScriptStore.isScriptsLoaded || !measureScriptStore.isScriptsLoaded)) || loadingStore.isLoading
}, true)
</script>

<template>
  <QLayout view="hHh Lpr lFf" class="m-auto" container>
    <QPageContainer class="full-height">
      <QPage :style-fn="pageTweak">
        <slot />
      </QPage>
    </QPageContainer>
    <QInnerLoading class="z-max" :showing="hasLoading" :transition-duration="500">
      <div class="fit absolute flex items-center justify-center bg-black">
        <img :src="CDN_ASSETS.logo" class="h-auto w-[120px]">
      </div>
    </QInnerLoading>
  </QLayout>
</template>
