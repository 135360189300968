<script setup lang='ts'>
const { appStore } = useStores()
</script>

<template>
  <div class="tabs">
    <div class="tabs-menu shadow-2">
      <div class="tabs-pointer" :class="`active-${appStore.values.defaultActiveRootTab}`" />
      <div v-for="(tab, index) in appStore.renderTabsNav" :key="tab.id" class="tabs-menu--item" :class="{ 'is-active': appStore.currentActiveRootTab(tab.id) }" @click="appStore.setActiveRootTab(tab.id)">
        <div class="item-icon">
          <CommonIcon :name="tab.id" />
        </div>
        <span class="item-label drop-shadow-md" :data-vertical="index + 1">
          {{ tab.label }}
        </span>
      </div>
    </div>
  </div>
</template>
