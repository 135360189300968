<script setup lang="ts">
import { useMeasureConfig } from '../measure.model'
import MeasureResult from './measure-result.vue'
import { META } from '@/configs/app.config'

interface IProps {
  compact: boolean
}

interface ITemplateProps {
  label: string
  type: StringWithAutocompleteOptions<'default' | 'success' | 'error'>
  icon: string
}

withDefaults(defineProps<IProps>(), {
  compact: true,
})
const emits = defineEmits(['close'])
const { appStore, measureScriptStore } = useStores()

const skipTour = useStorage(`${META.appName}_measurement_skip_tour`, false)

const {
  camera,
  videoCanvas,
  load,
  garbageCollect,
  getPd,
  finish,
  faceValidColor,
  cardValidColor,
  // holdCardColor,
  retake,
  current,
  goTo,
  isCurrent,
  goToNext,
} = useMeasureConfig()
const IconTemplate = createReusableTemplate<ITemplateProps>()
const renderGuiding = computed(() => {
  return [
    {
      icon: 'firstGuiding',
      label: 'Take off your glasses and your hat.',
    },
    {
      icon: 'secondGuiding',
      label: 'Stay in a well-lit space.',
    },
    {
      icon: 'thirdGuiding',
      label: 'Prepare magnetic 2.13" x 3.38" card (Visa, Master, ATM ...)',
    },
  ]
})

const [isHover, setHover] = useToggle(false)
const video = ref()
const { playing } = useMediaControls(video, {
  src: {
    src: 'https://d3lgac2qoojxa5.cloudfront.net/assets/pd-measurement-voice-instruction/instruction.mp4',
    type: 'video/mp4',
  },
})

onMounted(() => {
  if (skipTour.value)
    goTo('measure')
})

watch([
  current,
  () => measureScriptStore.isScriptsLoaded,
  () => appStore.isOpenDialog,
  () => appStore.isAppEmbedded,
], ([valCurrent, isScriptsLoaded, isOpenDialog, isAppEmbedded]) => {
  const mustCondition = valCurrent === 'measure' && isScriptsLoaded
  const optionalCondition = isOpenDialog || isAppEmbedded
  mustCondition && optionalCondition && load(isScriptsLoaded)
}, { flush: 'pre', immediate: true })

watch([
  () => appStore.isOpenDialog,
], () => {
  garbageCollect()
})
onBeforeUnmount(() => {
  garbageCollect()
})
</script>

<template>
  <IconTemplate.define v-slot="{ label, type, icon }">
    <QItem dense>
      <QItemSection avatar class="!min-w-0">
        <CommonTransition name="q-transition--scale">
          <QIcon
            v-if="type === 'error'"
            size="xs"
            name="cancel"
            class="z-top absolute text-red !left-2 !-top-[4px]"
          />
          <QIcon
            v-else-if="type === 'success'"
            size="xs"
            name="check_circle"
            class="z-top absolute text-green !left-2 !-top-[4px]"
          />
        </CommonTransition>
        <QAvatar
          rounded
          class="bg-transparent transition-all duration-200 flex items-center !size-[52px]"
          :class="{
            'guide-card-green': type === 'success',
            'guide-card-red': type === 'error',
          }"
        >
          <CommonIcon :name="icon" class="!size-[52px] !-top-px left-px" />
        </QAvatar>
      </QItemSection>
      <QItemSection>
        <QItemLabel class="text-body1" :class="{ 'text-weight-bold': type !== 'default' }">
          {{ label }}
        </QItemLabel>
      </QItemSection>
    </QItem>
  </IconTemplate.define>

  <!-- Step 1 -->
  <QCard
    v-if="isCurrent('introduction')"
    flat
    class="fit column bg-white"
  >
    <section class="grid grid-rows-[auto_1fr] flex-1 pt-[50px] md:mx-auto md:max-w-[414px]">
      <QCardSection class="column q-py-none">
        <QItemLabel class="text-h6 text-weight-bold q-mb-md text-center">
          Face Measurement
        </QItemLabel>
        <div class="relative-position h-[214px] cursor-pointer" @mouseover="setHover(true)" @mouseleave="setHover(false)">
          <video
            ref="video"
            crossorigin="anonymous"
            class="fit rounded object-cover"
            playsinline
            @click="playing = !playing"
          />
          <QBtn class="absolute-center transition-all duration-200" :class="isHover ? 'opacity-100' : 'opacity-0'" flat color="primary" padding="0" size="40px" round :icon="!playing ? 'play_circle_filled' : 'pause_circle_filled'" @click="playing = !playing" />
        </div>
      </QCardSection>
      <QCardSection class="column q-py-xs">
        <div class="column flex-1">
          <QItemLabel class="text-weight-bold text-subtitle1 q-my-xs">
            Before getting started:
          </QItemLabel>
          <QItem v-for="(item, index) in renderGuiding" :key="index">
            <QItemSection avatar>
              <QAvatar size="44px">
                <CommonIcon :name="item.icon" />
              </QAvatar>
            </QItemSection>
            <QItemSection>
              <QItemLabel class="text-body2 text-black/70">
                {{ item.label }}
              </QItemLabel>
            </QItemSection>
          </QItem>
        </div>
      </QCardSection>
      <QCardSection class="column">
        <QItemLabel caption class="q-mt-md q-mb-sm text-weight-normal text-body2 text-center text-black/70">
          By tapping "Start", you agree with our
          <a
            class="cursor-pointer text-blue"
            href="https://glasson.io/privacy.html"
            target="_blank"
          >privacy policy</a>
        </QItemLabel>
        <div class="text-center">
          <CommonMainButton
            label="Start"
            class="text-weight-bold text-body2"
            @click="goToNext"
          />
        </div>
        <QCheckbox v-model="skipTour" dense label="Don't show this page next time" class="q-mt-md text-body2" />
      </QCardSection>
    </section>
  </QCard>

  <!-- Step 2 -->
  <section v-if="isCurrent('measure')" class="fit column relative-position bg-grey-5">
    <div ref="camera" class="relative-position h-4/5 sm:h-full">
      <div v-if="getPd" class="scan absolute-center">
        <div class="fingerprint h-[300px] w-[250px] md:h-[60vh] md:w-[320px] md:w-[50vh]" />
      </div>
      <canvas id="video_canvas" ref="videoCanvas" class="fit object-cover" />
      <video id="video" playsinline style="display: none" />
    </div>

    <div
      class="full-width absolute bottom-0 left-1/2 z-20 -translate-x-1/2"
    >
      <section class="column fit mx-auto max-w-[474px] justify-evenly">
        <div
          v-if="!finish"
          class="q-pa-sm rounded-t-[16px] bg-black/50 text-white backdrop-blur-md"
        >
          <QItemLabel class="text-h6 text-weight-bold q-mb-sm text-center text-white">
            Steps To Measure
          </QItemLabel>
          <IconTemplate.reuse
            label="Put your face in the dotted area."
            icon="face"
            :type="faceValidColor"
          />
          <IconTemplate.reuse
            label="Place the card touch to your forehead as described."
            :icon="faceValidColor === 'success' ? 'hold' : 'stick_card'"
            :type="faceValidColor === 'success' ? cardValidColor : 'default'"
          />
        </div>
        <MeasureResult v-else @retake="retake" @close="emits('close')" />
      </section>
    </div>
  </section>

  <!-- Step 2 -->
  <!-- <section v-if="isCurrent('result')" class="relative-position fit max-w-[500px] min-w-[80%]">
    <QImg :src="appStore.values.pd.measureImageUrl" class="h-[calc(100%-274px)] md:h-full" fit="contain" />
    <div
      class="relative h-[274px] md:absolute md:bottom-0 md:left-1/2 md:-translate-x-1/2"
    >
      <section class="fit">
        <MeasureResult @retake="retake" @close="emits('close')" />
      </section>
    </div>
  </section> -->
</template>
