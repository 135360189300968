import { z } from 'zod'

export const LayoutSchema = z.object({
  logo: z.string().optional(),
  tintColor: z.string().optional(),
  font: z.string().optional(),
  showDescription: z.boolean().optional(),
  showLive: z.boolean().optional(),
  show360: z.boolean().optional(),
  showVideo: z.boolean().optional(),
  showFrames: z.boolean().optional(),
  showLens: z.boolean().optional(),
  showPD: z.boolean().optional(),
  showCTA: z.boolean().optional(),
  enableGlassesRemoval: z.boolean().optional(),
  ctaBtn: z.object({
    icon: z.string().optional(),
    text: z.string().optional(),
    functionName: z.string().optional(),
  }).optional(),
  accountId: z.number().nullish(),
  account: z.object({
    subscribedServices: z.object({
      pd: z.boolean(),
      pdUrl: z.string(),
    }),
    status: z.string(),
    shoplazzaPlan: z.string(),
  }).optional(),
  rootTabNav: z.array(
    z.object({
      label: z.string(),
      id: z.string(),
    }),
  ).optional(),
})

export const LenSchema = z.object({
  id: z.number(),
  sku: z.string().optional(),
  texture: z.string().nullish(),
  color: z.string().optional(),
  reflection: z.number().optional(),
  brightness: z.number().optional(),
  contrast: z.number().optional(),
  saturation: z.number().optional(),
  created: z.string().optional(),
  updated: z.string().optional(),
  disable: z.boolean().optional(),
  defaultActive: z.boolean().optional(),
})

export const ProductSchema = z.object({
  id: z.number(),
  sku: z.string().optional(),
  accountId: z.string().nullish(),
  gender: z.string(),
  glassesType: z.string(),
  frameShape: z.string(),
  frameType: z.string(),
  modelType: z.string(),
  model: z.object({
    photos: z.string(),
    zipUrl: z.string(),
    newZipUrl: z.string(),
  }),
  isGLB: z.boolean(),
})

export const GroupSchema = z.object({
  name: z.string(),
  filter: z.array(z.string()),
  status: z.string(),
  desc: z.string(),
  glassesWidth: z.number().optional(),
  lensOptions: z.array(z.string()).optional(),
  created: z.string().optional(),
  updated: z.string().optional(),
  id: z.union([z.string(), z.number()]),
  accountId: z.number().nullish(),
  product: z.array(ProductSchema),
  lens: z.array(LenSchema),
  hasBuildInLens: z.boolean().optional(),
  frameTabNav: z.array(
    z.object({
      label: z.string(),
      id: z.string(),
    }),
  ).optional(),
})

export const PDSchema = z.object({
  measurePd: z.union([z.string(), z.number()]).pipe(z.coerce.number()),
  pd: z.union([z.string(), z.number()]).pipe(z.coerce.number()),
  leftPd: z.union([z.string(), z.number()]).pipe(z.coerce.number()),
  rightPd: z.union([z.string(), z.number()]).pipe(z.coerce.number()),
  noseBridgeWidth: z.union([z.string(), z.number()]).pipe(z.coerce.number()),
  faceWidth: z.union([z.string(), z.number()]).pipe(z.coerce.number()),
  innerCanthalDistance: z.union([z.string(), z.number()]).pipe(z.coerce.number()),
})

export const RootStateSchema = z.object({
  layout: LayoutSchema,
  group: GroupSchema,
  currentSelectedFrame: ProductSchema.nullish(),
  currentSelectedLen: LenSchema.nullish(),
  defaultActiveRootTab: z.string(),
  defaultActiveFrameTab: z.string(),
  defaultZoom: z.number(),
  screen: z.enum(['home', 'measurement']),
  isOpen: z.boolean(),
  isAppEmbedded: z.boolean().optional(),
  hasAttention: z.boolean().optional(),
  pd: PDSchema,
})

export enum OutputEvent {
  pdResult = 'ok',
  closeModel = 'close',
  // addToMyList = 'addToMyList',
  // showProductList = 'showProductList',
  // showProductDetail = 'showProductDetail',
  // shareBySns = 'shareBySns',
  // shareByLine = 'shareByLine',
  // showMyList = 'showMyList',
  // showPages = 'showPages',
  // showChatbot = 'showChatbot',
  // goBack = 'back',
  // decision = 'decision',
}

export type TLayout = z.infer<typeof LayoutSchema>
export type TLen = z.infer<typeof LenSchema>
export type TProduct = z.infer<typeof ProductSchema>
export type TGroup = z.infer<typeof GroupSchema>
export type TRootState = z.infer<typeof RootStateSchema>
