<script setup lang="ts">
import { TryOnConfig, TryOnWidget } from '@/features/try-on'
import { MeasureToolbar, MeasureWidget } from '@/features/measure'

const { appStore } = useStores()
const { currentSelectedFrame } = storeToRefs(appStore)
const currentScreen = toRef(appStore.values.screen)
function onClose() {
  appStore.setOpenDialog(false)
  goHome()
}
function goHome() {
  currentScreen.value = 'home'
}
</script>

<template>
  <LayoutDefault>
    <template #default>
      <section class="fit">
        <CommonHeader :hide-close="!appStore.isAppEmbedded" @close="onClose">
          <MeasureToolbar
            v-if="appStore.currentActiveRootTab('live') && appStore.hasEnablePdMeasurement"
            v-model="currentScreen"
          />
          <div v-else class="flex-1" />
        </CommonHeader>
        <template v-if="currentScreen === 'home'">
          <!-- Show the TryOnWidget only when the currentSelectedFrame is not null, to prevent a race condition -->
          <TryOnWidget v-if="currentSelectedFrame" />
          <TryOnConfig />
        </template>
        <MeasureWidget v-else-if="currentScreen === 'measurement'" @close="goHome" />
      </section>
    </template>
  </LayoutDefault>
</template>
