import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { get } from 'radash'
import { parse } from 'zipson'
import { promiseTimeout } from '@vueuse/core'
import type { TGroup, TLayout, TLen, TProduct, TRootState } from './app.types'
import { RootStateSchema } from './app.types'
import { DEFAULT_ACTIVE_ROOT_TAB, defaultState, getDefaultActiveFrame, getDefaultPD, transformGroupData, transformLayoutSetting } from './app.lib'
import GlassonApis from '@/shared/apis'
import { META } from '@/configs/app.config'

export const NAMESPACE = 'app-global-module'
export const use = defineStore(NAMESPACE, () => {
  const { getDefaultZoom } = useEnhancer()
  const local = localStorage.getItem(`__${META.appName}__${NAMESPACE}`)
  const cacheData: AnyObject = local ? parse(local) : {}

  const { values, defineField, setValues, setFieldValue } = useForm<TRootState>({
    validationSchema: toTypedSchema(RootStateSchema),
    initialValues: defaultState(cacheData.values),
  })

  const sourceStorage = useStorage(`user.pd`, values.pd)
  const [userPd] = defineField('pd')
  syncRefs(userPd, sourceStorage)

  const [isOpenDialog] = defineField('isOpen')
  const [isAppEmbedded] = defineField('isAppEmbedded')
  const [hasAttention] = defineField('hasAttention')

  const layoutSettingCtx = useAsyncState(
    async (userId: string | number) => await GlassonApis.layoutSettings(userId),
    null,
    {
      immediate: false,
      onSuccess(data) {
        if (data)
          setLayout(data)
      },
    },
  )

  const groupDataCtx = useAsyncState(
    async (groupId: string | number) => await GlassonApis.groupById(groupId),
    null,
    {
      immediate: false,
      onSuccess(data) {
        if (data)
          setGroup(data)
      },
    },
  )

  // * Computed
  const isAllLoading = computed(() => layoutSettingCtx.isLoading.value || groupDataCtx.isLoading.value)
  const isAllReady = computed(() => layoutSettingCtx.isReady.value || groupDataCtx.isReady.value)
  const renderProducts = computed(() => get<TProduct[]>(values.group, 'product', []))
  const renderLens = computed(() => get<TLen[]>(values.group, 'lens', []))
  const renderTabsNav = computed(() => get<TLayout['rootTabNav']>(values.layout, 'rootTabNav', []))
  const renderMenusNav = computed(() => get<TGroup['frameTabNav']>(values.group, 'frameTabNav', []))

  // * Computed
  const currentActiveRootTab = computed(() => (id: string) => values.defaultActiveRootTab === id)
  const currentActiveFrameTab = computed(() => (id: string) => values.defaultActiveFrameTab === id)
  const shouldSelectedFrameWith = computed(() => (id: number) => values.currentSelectedFrame?.id === id)
  const shouldSelectedLenWith = computed(() => (id: number) => values.currentSelectedLen?.id === id)
  const currentSelectedFrame = computed(() => values.currentSelectedFrame)
  const currentSelectedLen = computed(() => values.currentSelectedLen)
  const isAppMeasure = computed(() => values.screen === 'measurement')
  const isTryOn = computed(() => values.defaultActiveRootTab === 'live')
  const hasPdMeasure = computed(() => values.pd?.pd && values.pd?.faceWidth && values.pd?.noseBridgeWidth && values.pd?.innerCanthalDistance)
  const hasEnablePdMeasurement = computed(() => {
    const WHITE_LIST_PATH = ['demo-page']
    return (values.layout?.account?.subscribedServices?.pd && values.layout?.showPD) || WHITE_LIST_PATH.some(path => window.location.pathname.includes(path))
  })
  const hasEnableGlassesRemoval = computed(() => {
    const WHITE_LIST_PATH = ['demo-page']
    return values.layout?.enableGlassesRemoval || WHITE_LIST_PATH.some(path => window.location.pathname.includes(path))
  })
  const isEnterpriseCustomer = computed(() => {
    const ENTERPRISE_HOST = ['aldermiller.com', 'euglasses.com.br']
    return ENTERPRISE_HOST.some(host => window.location.hostname.includes(host))
  })
  const shouldShowDisclaimer = computed(() => {
    const ENTERPRISE_HOST = ['aldermiller.com']
    return ENTERPRISE_HOST.some(host => window.location.hostname.includes(host))
  })

  // * Actions Fetch
  const fetchLayoutSettings = async (id: string | number) => await layoutSettingCtx.execute(0, id)
  const fetchGroupData = async (id: string | number) => await groupDataCtx.execute(0, id)

  // * Actions Patch
  const setLayout = (layoutDataPayload: TLayout) => {
    const layoutData = transformLayoutSetting(layoutDataPayload)
    setValues({
      layout: layoutData,
      // defaultActiveRootTab: DEFAULT_ACTIVE_ROOT_TAB, // get(layoutData, 'rootTabNav[0].id', DEFAULT_ACTIVE_ROOT_TAB),
    })
  }

  const setGroup = (groupDataPayload: TGroup) => {
    const groupData = transformGroupData(groupDataPayload)

    setValues({
      group: groupData,
      defaultActiveFrameTab: getDefaultActiveFrame(groupData.product.length, groupData.lens.length), // get(groupData, 'frameTabNav[0].id', DEFAULT_ACTIVE_FRAME_TAB),
    })
  }

  const setSelectionFrame = (frameData: TProduct | null) => {
    setFieldValue('currentSelectedFrame', frameData)
  }

  const setSelectionLen = (lenData: TLen | null) => {
    setFieldValue('currentSelectedLen', lenData)
  }

  const setActiveRootTab = (id: string) => {
    setFieldValue('defaultActiveRootTab', id)
  }

  const setActiveFrameTab = (id: string) => {
    setFieldValue('defaultActiveFrameTab', id)
  }

  const setEnableGlassesRemoval = (value: boolean) => {
    setFieldValue('layout.enableGlassesRemoval', value)
  }

  const setEnablePdMeasurement = (value: boolean) => {
    setFieldValue('layout.showPD', value)
  }

  const setLenItem = (payload: TLen) => {
    setFieldValue('group', {
      ...values.group,
      lens: [payload],
    })
  }

  const setOpenDialog = (value: boolean) => {
    setFieldValue('isOpen', value)
    if (value)
      setFieldValue('hasAttention', true)
  }

  const setDefaultZoom = (value: number) => {
    setFieldValue('defaultZoom', value)
  }

  const setAppScreen = (screen: TRootState['screen']) => {
    setFieldValue('screen', screen)
  }

  const setAppEmbed = (value: boolean) => {
    setFieldValue('isAppEmbedded', value)
    if (value)
      setFieldValue('hasAttention', true)
  }

  // * PD Measure
  function updatePdMeasure(args: {
    faceWidth?: number
    bridgeDistance?: number
    pupilDistance?: number
    leftPd?: number
    rightPd?: number
    innerCanthalDistance?: number
  }) {
    const { faceWidth = 0, bridgeDistance = 0, pupilDistance = 0, leftPd = 0, rightPd = 0, innerCanthalDistance = 0 } = args
    setFieldValue('pd', {
      faceWidth,
      noseBridgeWidth: bridgeDistance,
      pd: pupilDistance,
      leftPd,
      rightPd,
      innerCanthalDistance,
      measurePd: pupilDistance,
    })
  }

  function setPupilDistance(value: number) {
    if (value < 0)
      return
    setFieldValue('pd', {
      ...values.pd,
      pd: value,
    })
  }

  function setFaceWidth(value: number) {
    if (value < 0)
      return
    setFieldValue('pd', {
      ...values.pd,
      faceWidth: value,
    })
  }

  function resetPdMeasure() {
    setFieldValue('pd', getDefaultPD())
  }

  const checkValidAccountAndProduct = (accountStatus: string, productStatus: string) => {
    return accountStatus === 'active' && productStatus === 'active'
  }

  const initialLoad = async (args: {
    userId?: string
    groupId?: string
    zoom?: number
    mode?: string
  }) => {
    const { userId, groupId, zoom = getDefaultZoom.value, mode = DEFAULT_ACTIVE_ROOT_TAB } = args
    if (userId)
      await fetchLayoutSettings(userId)
    // * Uncomment this line to simulate the slow loading for the group data
    // await promiseTimeout(1000)
    if (groupId)
      await fetchGroupData(groupId)
    // setActiveRootTab(mode)
    setDefaultZoom(zoom)
    setSelectionFrame(get<TProduct>(renderProducts.value, '[0]')) // setSelectionFrame
    // setSelectionLen(get<TLen>(renderLens.value, '[0]')) // setSelectionLen
  }

  const logAttention = async (userId: string) => {
    if (hasAttention.value)
      await GlassonApis.tickAttention(userId)
    else
      await GlassonApis.tickIgnore(userId)
  }

  return {
    // * State
    values,
    isOpenDialog,
    isAppEmbedded,
    // * Computed
    layoutSettings: computed(() => values.layout),
    groupData: computed(() => values.group),
    isAppMeasure,
    isTryOn,
    currentActiveRootTab,
    currentActiveFrameTab,
    currentSelectedFrame,
    currentSelectedLen,
    shouldSelectedFrameWith,
    shouldSelectedLenWith,
    isAllLoading,
    isAllReady,
    renderProducts,
    renderLens,
    renderTabsNav,
    renderMenusNav,
    hasPdMeasure,
    hasEnablePdMeasurement,
    hasEnableGlassesRemoval,
    isEnterpriseCustomer,
    shouldShowDisclaimer,
    // * Actions
    fetchLayoutSettings,
    initialLoad,
    setAppScreen,
    setLayout,
    setGroup,
    setActiveRootTab,
    setActiveFrameTab,
    setLenItem,
    setAppEmbed,
    setOpenDialog,
    setDefaultZoom,
    checkValidAccountAndProduct,
    setSelectionFrame,
    setSelectionLen,
    updatePdMeasure,
    setFaceWidth,
    resetPdMeasure,
    setPupilDistance,
    setEnableGlassesRemoval,
    setEnablePdMeasurement,
    logAttention,
  }
}, {
  persist: true,
})
